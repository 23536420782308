import "bootstrap5";
import "./signup_login.scss";

// Make jQuery available globally
// Because we use it inline
import $ from "jquery";
window.$ = $;
window.jQuery = $;

import "jquery-validation/dist/jquery.validate.min";

import rails from "@rails/ujs";
import "react-select2-wrapper";

rails.start();

// HACK: This still needs to be a require because it's not a module. It uses
// jQuery without importing it. For some reason webpack dose not confine the
// the scope the same with requires and imports
require("actions/base_pages_login");

// Import images
const images = require.context("../images/signup_login", true);
const imagePath = (name) => images(name, true);
