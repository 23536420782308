/*
 *  Document   : base_pages_login.js
 *  Author     : ikantam
 *  Description: Custom JS code used in Login Page
 */

var ruleHash = {
  "user[login]": {
    required: true,
    minlength: 3,
  },
  "user[current_password]": {
    required: true,
    minlength: 8,
  },
  "user[password]": {
    validatePassword: true,
  },
  "user[text_password]": {
    validatePassword: true,
  },
  "user[name]": {
    required: true,
  },
  "user[first_name]": {
    required: true,
  },
  "user[last_name]": {
    required: true,
  },
  "user[office_phone]": {
    required: true,
  },
  "user[email]": {
    required: true,
    email: true,
  },
  "user[company_name]": {
    required: true,
  },
  "user[country]": {
    required: true,
  },
};
var messageHash = function (e) {
  return {
    "user[login]": loginOrCntPwdMsg(true),
    "user[current_password]": loginOrCntPwdMsg(false),
    "user[password]": pwdValMsg(true),
    "user[text_password]": pwdValMsg(true),
    "user[first_name]": { required: i18ntxt.first_name + " " + i18ntxt.blank },
    "user[last_name]": { required: i18ntxt.last_name + " " + i18ntxt.blank },
    "user[office_phone]": { required: i18ntxt.office_phone + " " + i18ntxt.blank },
    "user[email]": {
      required: i18ntxt.email + " " + i18ntxt.blank,
    },
    "user[company_name]": {
      required: i18ntxt.company_name + " " + i18ntxt.blank,
    },
    "user[country]": {
      required: i18ntxt.country + " " + i18ntxt.blank,
    },
  };
};

var loginRuleHash = {
  "user[login]": {
    required: true,
    minlength: 3,
  },
  "user[password]": {
    required: true,
    minlength: 8,
    maxlength: 128,
  },
};
var loginMsgHash = function (e) {
  return {
    "user[login]": loginOrCntPwdMsg(true),
    "user[password]": pwdValMsg(false),
  };
};

var pwdValMsg = function (forSignup) {
  return {
    required: i18ntxt.password + " " + i18ntxt.blank,
    minlength: forSignup ? i18ntxt.too_short : i18ntxt.current_too_short,
    maxlength: i18ntxt.password + " " + i18ntxt.too_long_message,
  };
};

var loginOrCntPwdMsg = function (isLogin) {
  return {
    required: (isLogin ? i18ntxt.login : i18ntxt.current_password) + " " + i18ntxt.blank,
    minlength: (isLogin ? i18ntxt.login : i18ntxt.current_password) + " " + i18ntxt.too_short_message,
  };
};

var baseValidation = function (forSignup) {
  element = forSignup ? ".js-validation-sign-up" : ".js-validation-login";

  jQuery(element).validate({
    errorClass: "help-block col-xs-12 animated fadeInDown",
    errorElement: "div",
    errorPlacement: function (error, e) {
      jQuery(e).parents(".form-group").append(error);
    },

    highlight: function (e) {
      jQuery(e).closest(".form-group").removeClass("has-error").addClass("has-error");
      jQuery(e).closest(".help-block").remove();
    },

    success: function (e) {
      jQuery(e).closest(".form-group").removeClass("has-error");
      jQuery(e).closest(".help-block").remove();
    },

    rules: forSignup ? ruleHash : loginRuleHash,
    messages: forSignup ? messageHash() : loginMsgHash(),
  });
};

var signupValidation = function () {
  baseValidation(true);
};

var loginValidation = function () {
  baseValidation(false);
};

var validateLength = function (value) {
  if (value.length >= 10 && value.length <= 128) {
    $("#length_range .fa-check").removeClass("hidden");
    $("#length_range .fa-times").addClass("hidden");
  }
};

var validateRegex = function (value, regex, element) {
  if (regex.test(value)) {
    $(element + " .fa-check").removeClass("hidden");
    $(element + " .fa-times").addClass("hidden");
  }
};

var passwordErros = function () {
  $(".psw_error_wrapper").fadeIn();
  $(".psw_error_wrapper").removeClass("psw_error_hidden").addClass("psw_error_visible");
  $(".psw_error_wrapper .fa-check").addClass("hidden");
  $(".psw_error_wrapper .fa-times").removeClass("hidden");
};

var checkAllValidations = function (value) {
  var valid;
  var hasNumber = /\d/;
  var hasCapital = /[A-Z]/;
  var hasSmall = /[a-z]/;
  var hasSpecial = /[!.@#$%^&\W*()_=\+|\-|\[|\]|{}|':<>?\/|[\]\\]/;

  passwordErros();
  validateLength(value);
  validateRegex(value, hasNumber, "#atleast_number");
  validateRegex(value, hasCapital, "#atleast_capital");
  validateRegex(value, hasSmall, "#atleast_lower");
  validateRegex(value, hasSpecial, "#atleast_special");
};
function passwordStrengthRegex(){
  var value = $("#password_regix").val().slice(7, -1).split(' ').join('');

  return new RegExp(value);
}
var validateFormPassword = function () {
  $.validator.addMethod(
    "validatePassword",
    function (value, element) {
      var passwordStrength = passwordStrengthRegex();
      if (passwordStrength.test(value)) {
        validateRegex(value, passwordStrength, ".psw_error_wrapper");
        valid = true;
      } else {
        checkAllValidations(value);

        valid = false;
      }

      return valid;
    },
    " "
  );
};

var BasePagesLogin = (function () {
  // Init Login Form Validation, for more examples you can check out https://github.com/jzaefferer/jquery-validation
  var initValidationLogin = function () {
    signupValidation();
    loginValidation();
  };

  return {
    init: function () {
      // custom password validation method for signup forms
      validateFormPassword();
      initValidationLogin();
    },
  };
})();

jQuery(function () {
  BasePagesLogin.init();
  $("#user_password").on("focus", function () {
    $(".psw_error_wrapper").fadeIn();
    $(".psw_error_wrapper").removeClass("psw_error_hidden");
  });

  $("#user_password").on("focusout", function () {
    // fading the errors list if the password is valid on focus out
    $("#user_password").valid();
    var passwordStrength = passwordStrengthRegex();
    if (passwordStrength.test($("#user_password").val())) {
      $(".psw_error_wrapper").fadeOut();
    }
  });

  $("#user_password").on("keyup", function () {
    $(".psw_error_wrapper").addClass("psw_error_visible");
    $("#user_password").valid();
  });

  $("#user_country").on("select2:close", function (e) {
    $(this).valid();
  });
});

jQuery(function () {
  const passwordInput = $("[type='password']");
  $("#shw-pwd-btn").on("click", function () {
    togglePasswordButton = $("#shw-pwd-btn").find("i");
    if (passwordInput.attr("type") === "password") {
      passwordInput.attr("type", "text");
      togglePasswordButton.removeClass("fa-eye-slash");
      togglePasswordButton.addClass("fa-eye");
    } else {
      togglePasswordButton.addClass("fa-eye-slash");
      togglePasswordButton.removeClass("fa-eye");
      passwordInput.attr("type", "password");
    }
  });

  $("#get_started_form").on("submit", function () {
    dataLayer.push({ event: "sign-up-get-started-button-click" });
  });
});
